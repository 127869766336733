<template>
  <div class="newsletter__wrap">
    <a-collapse-transition>
      <a-form
        :key="prefilledForm_reRenderKey"
        :is-term-accepted.sync="isTermAccepted"
        :captcha-key.sync="captchaKey"
        :form-type="FORM_TYPE.HP_NEWSLETTER"
        :in-progress="inProgress"
        hide-submit-button
        :form-validation-fn="$_componentValidation_isFormValid"
        :external-settings="formSettings"
        :errors="$v"
        :form-id="$options.consts.FORM_ID.NEWSLETTER_EVENTS"
        class="newsletter__form"
        ref="form"
        @submit="submitForm"
      >
        <fieldset>
          <div class="newsletter__form__email__wrap">
            <a-text-input
              type="email"
              v-model="userForm.Email"
              :error="$v.userForm.Email"
              :placeholder="newsletter.Placeholder"
              class="newsletter__form__email__input"
            />
            <a-button
              :button-style="BUTTON_STYLE.BLACK"
              type="submit"
              class="newsletter__form__button"
            >
              {{ newsletter.JoinNewsletterButton.Title }}
            </a-button>
          </div>
        </fieldset>
      </a-form>
    </a-collapse-transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { email, required } from 'vuelidate/lib/validators'

import mixins from '@/utils/mixins'
import { BUTTON_STYLE } from 'shared/AButton'
import AForm, { FORM_TYPE } from 'shared/AForm'
import { FORM_ID } from 'enums/form-id'
import ACollapseTransition from 'shared/ACollapseTransition'

const SUCCESS_MESSAGE_TITLE = 'Success'
const SUCCESS_MESSAGE_TEXT =
  'Thank you for joining, you will occasionally receive  our upcoming events newsletter.'

export default {
  name: 'ANewsletterEvents',
  mixins: [
    mixins.prefilledForm(FORM_TYPE.HP_NEWSLETTER),
    mixins.componentValidation
  ],
  components: {
    AForm,
    ACollapseTransition
  },
  props: {
    newsletter: {
      type: Object,
      required: true
    }
  },
  consts: {
    FORM_ID
  },
  data() {
    return {
      BUTTON_STYLE,
      FORM_TYPE,
      userForm: this.$_prefilledForm_getPrefilledModel(),
      isTermAccepted: this.newsletter.DefaultCheckboxState,
      captchaKey: '',
      inProgress: false
    }
  },
  validations: {
    userForm: {
      Email: {
        required,
        email
      }
    },
    isTermAccepted: {
      required: val => val
    }
  },
  computed: {
    formSettings() {
      return {
        Gdpr: this.newsletter.CheckboxText,
        SuccessMessageText: SUCCESS_MESSAGE_TEXT,
        SuccessMessageTitle: SUCCESS_MESSAGE_TITLE,
        Title: this.newsletter.Title,
        TopText: '',
        Type: FORM_TYPE.HP_NEWSLETTER
      }
    }
  },
  methods: {
    ...mapActions({
      requestEventsNewsletterSubscription:
        'leads/requestEventsNewsletterSubscription'
    }),
    getPayload() {
      return {
        FirstName: this.userForm.FirstName,
        LastName: this.userForm.LastName,
        Email: this.userForm.Email
      }
    },
    cleanForm() {
      this.$nextTick(() => {
        this.userForm.Email = ''
        this.captchaKey = ''
        this.$v.$reset()
      })
    },
    async submitForm() {
      if (this.$helper.isValidationFailed(this)) return

      this.inProgress = true
      try {
        await this.requestEventsNewsletterSubscription({
          captchaKey: this.captchaKey,
          payload: this.getPayload()
        })
        await this.$refs.form.showSuccessMessage()
        this.cleanForm()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$section-size: 518px;

.newsletter__wrap {
  width: percentage($section-size/$desktop-full-hd-container);

  @include desktop-lg {
    width: 50%;
    padding: 30px 0;
  }

  @include desktop-md {
    width: 65%;
  }

  @include desktop-sm {
    width: 75%;
  }

  @include mobile {
    width: 100%;
  }
}

.newsletter__form {
  /deep/ .form__row {
    margin-bottom: 15px;
  }

  /deep/ .checkbox__label {
    font-size: 14px;
  }

  /deep/ .gdpr_container {
    margin-top: 0;
  }

  /deep/ .success-message {
    padding: 10px 50px 20px;
  }

  /deep/ .success-message__title {
    margin-top: 10px;
  }
}

.newsletter__form__email__wrap {
  display: flex;
  margin-bottom: 20px;

  /deep/ .input .text-field {
    border-color: $c--black;
  }
}

.newsletter__form__button {
  min-width: 190px;
  white-space: nowrap;
}

.newsletter__form__email__input {
  width: 60%;
}
</style>
